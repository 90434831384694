/** dialog **/
.container-dialog-content-custom {
    pointer-events: auto;
}

.container-dialog-content-custom > * {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
        rgba(0, 0, 0, 0.14) 0px 5px 8px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;
}

.confirm-dialog-yes-no {
    width: 350px;
    height: auto;
    min-height: 150px;
    max-height: 200px;
    border-radius: 10px;
    display: grid;
    grid-template-rows: auto 1fr 45px;
    background: #ffffff;
    user-select: none;
}

.confirm-dialog-yes-no > .confirm-dialog-yes-no-title {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    padding: 16px 24px;
    max-width: 100%;
}

.confirm-dialog-yes-no > .confirm-dialog-yes-no-content {
    padding: 10px 8px 20px 8px;
    min-height: 50px;
    max-width: 100%;
    text-align: center;
}

.confirm-dialog-yes-no > .confirm-dialog-yes-no-footer {
    display: flex;
    width: 100%;
    border-top: 1px solid #f0f0f0;
}

.confirm-dialog-yes-no > .confirm-dialog-yes-no-footer > * {
    height: 100%;
    flex: 1 1 auto;
    font-weight: 600;
}

.confirm-dialog-yes-no > .confirm-dialog-yes-no-footer > *:not(:last-child) {
    border-right: 1px solid #f0f0f0;
    color: crimson;
}
/** end dialog **/